.float {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: #1aa090;
	color: rgb(255, 255, 255);
	border-radius: 50px;
	border-color: #075e54;
	text-align: center;
}

.my-float {
	margin-top: 10px;
}

@media only screen and (max-width: 991px) {
	.lgIcon {
		margin-bottom: 2px;
		font-size: 20px;
	}
	.fullWidthInSmallScreens {
		width: 100%;
	}
}

@media (min-width: 400px) and (max-width: 991px) {
	.content2 {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		width: 100%;
		max-width: 300px;
		border-radius: 10px;
		max-height: 350px;
		background: rgba(238, 237, 237, 0.7);
	}
}

.react-datepicker-wrapper {
	width: 100%;
}
.fgINsU {
	padding-left: 0px !important;
}

.modal-body {
	max-height: 450px !important;
	overflow-y: scroll;
}

.required:after {
	content: " *";
	color: red;
}

.packagesBox {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.react-datepicker__navigation-icon .react-datepicker__.navigation-icon--previous {
	display: block !important;
}
.packagesBg1 {
	width: 100%;
	height: 400px;
	background-image: url("/src/1.jpeg");
	background-size: cover;
	background-position-y: center;
}

.packagesBg2 {
	width: 100%;
	height: 400px;
	background-image: url("/src/2.jpeg");
	background-size: cover;
	background-position-y: center;
}

.bgHome {
	background: linear-gradient(0deg, rgb(196, 117, 37, 0.3), rgb(196, 117, 37, 0.3)), url("../home.jpeg");
	/* background-blend-mode: color; */
	min-height: 100vh;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.menuBg {
	height: 300px;
	width: 100%;
}

.bg-light {
	background-color: white !important;
}
.stickyFooter {
	position: fixed;
	bottom: 0;
}

.menuitembox2 {
	/* padding-left: 10px; */
	margin-top: 10px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.brandcolor {
	color: #c47525 !important;
}
.brandcolor2 {
	color: grey;
}
.foodcategmenu {
	background-color: #fff;
	overflow: auto;
	white-space: nowrap;
	height: 47px;
	border-top: 3px solid #eee;
	border-bottom: 3px solid #eee;
}

.foodcategmenu a {
	display: inline-block;
	color: #06bee9;
	text-align: center;
	padding: 2px 14px;
	text-decoration: none;
	height: 24px;
	position: relative;
	margin-top: 3px;
	font-weight: 600;
	margin-left: 10px;
}

.foodcategmenu a.active {
	background: #06bee9;
	color: #fff;
	border-radius: 6px;
}

.brandcolor a.active {
	background: #c47525;
}

.categ {
	max-width: 1350px;
	margin: 0 auto;
	overflow: auto;
}

.content {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background: rgb(0, 0, 0, 0.5);
}

.content2 {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	width: 100%;
	max-width: 400px;
	border-radius: 10px;
	max-height: 350px;
	background: rgba(238, 237, 237, 0.7);
}

.noBorder {
	border: none !important;
}

.brandcolor a.active {
	color: white !important;
}

.contactIcon {
	background: #c47525;
	padding: 5px;
	border-radius: 5px;
}

.statusOpen {
	width: 17px;
	height: 17px;
	border-radius: 20px;
	display: inline-block;
	background: green;
}

.statusClosed {
	width: 17px;
	height: 17px;
	border-radius: 20px;
	display: inline-block;
	background: red;
}
